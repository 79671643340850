import styled from 'styled-components';
import { themeGet } from 'styled-system';

const VideoSectionWrapper = styled.section`
  padding: 60px 0px;
  overflow: hidden;
  margin-bottom:50px;

  .keterangan
  {
    width:70%;
    margin-left:auto;
    margin-right:auto;
  }

  @media (max-width: 990px) {
    padding: 60px 0;
    .button-distribusi
    {
      margin-top:300px;
    }
  }
  @media (max-width: 767px) {
    padding: 30px 0 70px 0;
    .button-distribusi
    {
      margin-top:230px;
      font-size:16px;
    }

    .keterangan
    { 
      width:100%;
    }
    
  }

  .figure {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; */
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @media (min-width: 1200px) {
      width: 930px;
    }

    img {
      border-radius: 4px;
    }

    .fig__caption {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

     

      .reusecore__button {
        .btn-icon {
          background-color: ${themeGet('colors.white', '#ffffff')};
          line-height: 0.4;
        }
      }
    }
  }
`;

export default VideoSectionWrapper;
