import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Box from "../../../reusecore/Box";
import Text from "../../../reusecore/Text";
import Heading from "../../../reusecore/Heading";
import Image from "../../../reusecore/Image";
import Container from "../../../reusecore/UI/Container";
import ProcessItem from "./workingProcess.style";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Image1 from "../../../assets/image/new/icon/unggas.png";
import Image2 from "../../../assets/image/new/icon/hewanbesar.png";
import Image3 from "../../../assets/image/new/icon/feedmill.png";
import Image4 from "../../../assets/image/new/icon/hewankesayangan.png";
import Image5 from "../../../assets/image/new/icon/hewanair.png";

const WorkingProcessSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  processRow,
  processCol,
  processImageStyle,
  processTitleStyle,
  processDescriptionStyle,
  processItemStyle,
  intl,
}) => {
  var emir = Object.keys(intl.messages).reduce((acc, item) => {
    if (item.includes("notfound.header")) {
      return [...acc, intl.messages[item]];
    }
    return acc;
  }, []);

  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text
            {...secText}
            content={<FormattedMessage id="produkTitleHome" />}
          />
          <Heading
            {...secHeading}
            content={<FormattedMessage id="produkDescHome" />}
          />
        </Box>

        <Box {...processRow}>
          {/* {Data.saasModernJson.homeProduk.map((item, index) => ( */}
          <Box
            {...processCol}
            key={`process-item-0`}
            className="process_item_col"
          >
            {/* <a href={item.slug}> */}
            <ProcessItem className="process_item" {...processItemStyle}>
              {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

              <Image src={Image1} alt="not found" {...processImageStyle} />

              <Heading
                as="h3"
                content={<FormattedMessage id="box1Title" />}
                {...processTitleStyle}
              />
              {[0, 1, 2, 3, 4, 5, 6].map((index) => (
                <Text
                  content={<FormattedMessage id={`box1List.${index}`} />}
                  {...processDescriptionStyle}
                />
              ))}
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            key={`process-item-1}`}
            className="process_item_col"
          >
            <ProcessItem className="process_item" {...processItemStyle}>
              {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

              <Image src={Image2} alt="not found" {...processImageStyle} />

              <Heading
                as="h3"
                content={<FormattedMessage id="box2Title" />}
                {...processTitleStyle}
              />
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                <Text
                  content={<FormattedMessage id={`box2List.${index}`} />}
                  {...processDescriptionStyle}
                />
              ))}
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            key={`process-item-2`}
            className="process_item_col"
          >
            <ProcessItem className="process_item" {...processItemStyle}>
              {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

              <Image src={Image3} alt="not found" {...processImageStyle} />

              <Heading
                as="h3"
                content={<FormattedMessage id="box3Title" />}
                {...processTitleStyle}
              />
              {[0, 1, 2, 3].map((index) => (
                <Text
                  content={<FormattedMessage id={`box3List.${index}`} />}
                  {...processDescriptionStyle}
                />
              ))}
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            key={`process-item-3`}
            className="process_item_col"
          >
            <ProcessItem className="process_item" {...processItemStyle}>
              {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

              <Image src={Image4} alt="not found" {...processImageStyle} />

              <Heading
                as="h3"
                content={<FormattedMessage id="box4Title" />}
                {...processTitleStyle}
              />
              {[0, 1, 2, 3, 4].map((index) => (
                <Text
                  content={<FormattedMessage id={`box4List.${index}`} />}
                  {...processDescriptionStyle}
                />
              ))}
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            key={`process-item-4`}
            className="process_item_col"
          >
            <ProcessItem className="process_item" {...processItemStyle}>
              {/* <ProcessIndex>{item.index || index + 1}</ProcessIndex> */}

              <Image src={Image5} alt="not found" {...processImageStyle} />

              <Heading
                as="h3"
                content={<FormattedMessage id="box5Title" />}
                {...processTitleStyle}
              />
              {[0, 1, 2, 3, 4].map((index) => (
                <Text
                  content={<FormattedMessage id={`box5List.${index}`} />}
                  {...processDescriptionStyle}
                />
              ))}
            </ProcessItem>
          </Box>

          {/* </a> */}
          {/* ))} */}
        </Box>
      </Container>
    </Box>
  );
};

WorkingProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object,
};

WorkingProcessSection.defaultProps = {
  sectionWrapper: {
    pt: ["60px", "15px", "15px", "15px", "15px"],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ["60px", "100px"],
  },
  secText: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "26px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "2rem",
  },
  secHeading: {
    textAlign: "center",
    fontSize: ["20px", "26px"],
    fontWeight: "500",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: "1.67",
  },
  processRow: {
    marginRight: "20px",
    flexBox: true,
    flexWrap: "wrap",
    flexDirection: "row",
    ml: ["0", "-15px", "-20px", "-35px", "-45px"],
    mr: ["0", "-15px", "-20px", "-35px", "-45px"],
  },
  processCol: {
    width: [1, 1 / 5],
    mr: "auto",
    ml: "auto",
    // pl: ["0", "15px", "20px", "35px", "45px"],
    // pr: ["0", "15px", "20px", "35px", "45px"],
    pl: ["0", "0", "0", "0", "45px"],
    pr: ["0", "0", "0", "0", "45px"],
    mb: "40px",
  },
  processImageStyle: {
    mb: "35px",
    width: ["100px", "80px", "70px", "auto"],
    marginLeft: "auto",
    marginRight: "auto",
  },
  processTitleStyle: {
    fontSize: ["20px", "18px", "18px", "20px", "20px"],
    color: "#0f2137",
    letterSpacing: "-0.02em",
    // mb: ["20px", "20px", "22px", "22px", "22px"],
    mb: ["5px", "15px", "15px", "15px", "15px"],
    textAlign: "center",
    fontWeight: "bold",
    mr: "auto",
    ml: "auto",
    minHeight: "50px",
  },
  processDescriptionStyle: {
    fontSize: ["18px", "15px", "15px", "15px"],
    fontWeight: "400",
    color: "#343d48",
    lineHeight: "1",
  },
  processItemStyle: {},
};

export default injectIntl(WorkingProcessSection);
