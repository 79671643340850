import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import Box from "../../../reusecore/Box";
import Text from "../../../reusecore/Text";
import Heading from "../../../reusecore/Heading";
import Button from "../../../reusecore/Button";
import Container from "../../../reusecore/UI/Container";
import VideoSectionWrapper from "./videoSection.style";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const VideoSection = ({
  sectionHeader,
  sectionTitle,
  buttonStyle,
  sectionSubTitle,
  imageWrapper,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      featureImage: file(relativePath: { eq: "image/new/peta.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <VideoSectionWrapper id="videoSection">
      <Container>
        <Box {...sectionHeader}>
          <Text
            content={<FormattedMessage id="homeCoverageTitle" />}
            {...sectionSubTitle}
          />
          <Heading
            className="keterangan"
            content={<FormattedMessage id="homeCoverageDesc" />}
            {...sectionTitle}
          />

          {/* <Heading
            content=""
            {...sectionTitle}
          /> */}
        </Box>
        <Box className="figure" {...imageWrapper}>
          <a href="/AreaDistribusi">
            <Image
              fluid={
                (Data.featureImage !== null) | undefined
                  ? Data.featureImage.childImageSharp.fluid
                  : {}
              }
              alt="Video Preview Image"
            />
          </a>
          <Box className="fig__caption">
            <a href="/AreaDistribusi">
              <Button
                className="button-distribusi"
                title={<FormattedMessage id="buttonCoverage" />}
                {...buttonStyle}
              />
            </a>
          </Box>
        </Box>
      </Container>
    </VideoSectionWrapper>
  );
};

// VideoSection style props
VideoSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  memberName: PropTypes.object,
  designation: PropTypes.object,
  imageWrapper: PropTypes.object,
};

// VideoSection default style
VideoSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ["20px", "20px"],
  },
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "26px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#84BD00",
    mb: "2rem",
  },
  // section title default style
  sectionTitle: {
    textAlign: "center",
    fontSize: ["20px", "26px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
    lineHeight: "1.67",
  },
  // button default design
  buttonStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "14px",
    marginTop: "30rem",
    fontWeight: "500",
    borderRadius: "15px",
  },
  imageWrapper: {
    display: " block",
    paddingBottom: "3rem",
  },
};

export default injectIntl(VideoSection);
