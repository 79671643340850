import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { agencyTheme } from "../theme/agency";
import { ResetCSS } from "../assets/css/style";
import {
  GlobalStyle,
  AgencyWrapper,
} from "../containers/reusableItem/agency.style";
import Navbar from "../containers/reusableItem/Navbar";
import BannerSection from "../containers/Home/BannerSection";
import ProdukKami from "../containers/Home/ProductSectionHome";
import CakupanKami from "../containers/Home/CakupanKamiSection";
import Prinsipal from "../containers/reusableItem/Prinsipal";
import Prinsipal1 from "../containers/reusableItem/Prinsipal/indexb";
import SliderNews from "../containers/reusableItem/ListNews/sliderNews";
import Footer from "../containers/reusableItem/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";

export default () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="AHD" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <ProdukKami />
          <CakupanKami />
          <Prinsipal />
          <Prinsipal1 />
          {/* <SliderNews /> */}
          <Footer />
        </AgencyWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
