import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import Box from "../../../reusecore/Box";
import Container from "../../../reusecore/UI/Container";
import { FeatureSliderWrapper } from "./cryptoSlides.style";
import { useStaticQuery, graphql } from "gatsby";

const FeatureSlider = ({ row }) => {
  const data = useStaticQuery(graphql`
    query {
      news: allContentfulNews(
        filter: { featuredNews: { eq: true } }
        limit: 3
        sort: { fields: updatedAt, order: DESC }
      ) {
        edges {
          node {
            imageNews {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);
  const imeg = data.news.edges.map(({ node }) => {
    return node.imageNews.fluid;
  });
  return (
    <FeatureSliderWrapper id="featureslider">
      <Container noGutter mobileGutter>
        <Box className="row" {...row}></Box>
        <Box className="FeatureSlider">
          <ImageGallery
            items={imeg}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay={true}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// Transactions style props
FeatureSlider.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
};

// Trusted default style
FeatureSlider.defaultProps = {
  // Trusted section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
    ml: "-15px",
    mr: "-15px",
  },
  title: {
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    fontWeight: "700",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    textAlign: "justify",
  },
  description: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#525f7f",
    lineHeight: "28px",
    mb: ["25px", "25px", "30px", "30px", "45px"],
    textAlign: "justify",
  },
  sectionSubTitle: {
    as: "span",
    fontSize: ["16px", "16px", "18px", "20px", "20px"],
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "27px",
    color: "#525f7f",
    textAlign: "justify",
  },
};

export default FeatureSlider;
