import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Box from "../../../reusecore/Box";
import Text from "../../../reusecore/Text";
import Heading from "../../../reusecore/Heading";
import Button from "../../../reusecore/Button";
import FeatureBlock from "../../../reusecore/FeatureBlock";
import Container from "../../../reusecore/UI/Container";
import Particles from "../../reusableItem/Particle";
import BannerWrapper from "./bannerSection.style";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl";

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  subTitle,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <a href="/TentangKami">
        <Button
          title={<FormattedMessage id="learnMoreButton" />}
          {...btnStyle}
        />
      </a>
    </Fragment>
  );
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  className="heading"
                  content="AGROVETA HUSADA DHARMA"
                  {...title}
                />
              }
              subTitle={
                <Text
                  className="subtitle"
                  content="To Improve Animal Health for a Better Human Life"
                  {...subTitle}
                />
              }
              description={
                <Text
                  content={<FormattedMessage id="bannerText3" />}
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center",
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "70%", "60%", "50%"],
  },
  title: {
    fontSize: ["26px", "34px", "42px", "55px"],
    fontWeight: "normal",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.31",
  },
  description: {
    fontSize: "20px",
    color: "black",
    lineHeight: "2.1",
    mb: "0",
  },
  btnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "14px",
    fontWeight: "500",
    background: "#84BD00",
    borderRadius: "15px",
  },
  outlineBtnStyle: {
    minWidth: ["130px", "156px"],
    fontSize: "14px",
    fontWeight: "500",
    color: "#0f2137",
    p: "5px 10px",
  },
  discountAmount: {
    fontSize: "14px",
    color: "#10AC84",
    mb: 0,
    as: "span",
    mr: "0.4em",
  },
  discountText: {
    fontSize: "14px",
    color: "#0f2137",
    mb: 0,
    as: "span",
  },
};

export default injectIntl(BannerSection);
